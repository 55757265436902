import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as fs from 'fs';
import Main from './components/main/Main';
import Cennik from './components/cennik/cennik';
import Trenerzy from './components/trenerzy/trenerzy';
import Rezerwacje from './components/rezerwacje/rezerwacje';
import Turnieje from './components/turnieje/turnieje';
import Serwis from './components/serwis/serwis';
import Sklep from './components/sklep/sklep';
import Kontakt from './components/kontakt/kontakt';
import Liga from './components/liga/liga';
import Jeden from './components/main/newsy/1';
import Drugi from './components/main/newsy/2';
import Trzeci from './components/main/newsy/3';
import Czwarty from './components/main/newsy/4';
import Regulamin from './components/regulamin/regulamin';
import LekchjeIndywidualne from './components/lekcjeIndywidualne/Main';
import Unia from './components/Unia/Main';
import { BrowserRouter as Routerito } from "react-router-dom";

const Router = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    <Route path="/o-nas-akademia-squash-rzeszow" component={Main} />
    <Route path="/squash-rzeszow-cennik" component={Cennik} />
    <Route path="/squash-z-trenerem-squash-rzeszow" component={Trenerzy} />
    <Route path="/squash-rzeszow-turniej-squasha" component={Turnieje} />
    <Route path="/squash-rzeszow-rezerwacje" component={Rezerwacje} />
    <Route path="/squash-rzeszow-lekcje-indywidualne-squash-z-trenerem" component={LekchjeIndywidualne} />
    <Route path="/squash-rzeszow-liga-squash" component={Liga} />
    <Route path="/squash-rzeszow-rakiety-do-gry-serwis-rakiet" component={Serwis} />
    <Route path="/squash-rzeszow-sklep" component={Sklep} />
    <Route path="/squash-rzeszow-kontakt" component={Kontakt} />
    <Route path="/Regulamin" component={Regulamin} />
    <Route path="/Unia" component={Unia} />
  </Switch>
);

export default Router;
