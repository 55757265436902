import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
}));
const Trenerzy = () => {
  const classes = useStyles();
  const lol = 'xD';
  const [trainers, setTrainers] = React.useState([
    {
      name: 'Jan Rochecki',
      position: 'Trener ESF Level 1',
      description: `Janek jest związany ze sportem od dziecka. Swoją przygodę ze squashem rozpoczął już kilkanaście lat 
      temu. Został trenerem squasha w 2016 roku i zaraża swoją pasją innych! Wprowadzi Was w świat 
      squasha oraz nauczy wszystkiego od podstaw. Jest cierpliwy i z łatwością tłumaczy wszystkie 
      zagadnienia. Janek nauczy grać w squasha osobę początkującą lub poprawi Twoje umiejętności! Uczy 
      każdego, zaczynając od naszych pociech! Janek prowadzi zajęcia dla dzieci, już od 5 roku życia!.`,
      src: `photos/trener.jpg`,
    },
    {
      name: 'Michał Kotra',
      position: 'Trener ESF Level 2',
      description: `Michał posiada przede wszystkim wieloletnie doświadczenie, jako trener oraz zawodnik.
      Prowadzi zajęcia dla średniozaawansowanych i zaawansowanych graczy.
      Jego osiągnięcia?
      Brązowy Medalista Indywidualnych Mistrzostw Polski w squashu 2019
      Złoty Medalista Drużynowych Mistrzostw Polski
      *Uczestnik i zwycięzca wielu turniejów
      *Jego najwyższy ranking w PZSQ to No 3
      *Jako Trener,  posiada złotych medalistów Mistrzostw Polski w kategoriach juniorskich.
      Jeśli grasz już długo w squasha, a czujesz że ,,stoisz w miejscu'', koniecznie przyjdź na trening do Michała `,
      src: `photos/kotramichalplakat_przyciete.png`,
    },
    {
      name: 'Sylwia Kotra ',
      position: 'Trenerka ESF Level 1',
      description: `Sylwia, to trenerka squasha oraz trenerka personalna.
      Od dziecka jej pasją jest sport, a szczególnie sporty rakietowe.
      Była zawodnikiem tenisa oraz trenerką przez 11 lat.
      Absolwentka Akademii Wychowania Fizycznego w Krakowie
      A w squashu?
      Medalistka Drużynowych Mistrzostw Polski oraz wielu turniejów
      Uczestniczyła w wielu turniejach ogólnopolskich
      Sylwia w naszym klubie Squash Akademia specjalizuje się przede wszystkim w zajęciach dla kobiet, ale nie tylko.
      Prowadzi zajęcia dla początkujących, średniozaawansowanych i zaawansowanych graczy squasha.`,
      src: `photos/SYLWIAPLAKAT_przyciete.png`,
    },
  ]);
  const [easeIn, setEaseIn] = React.useState(false);
  React.useEffect(() => {
    setEaseIn(true);
  }, []);
  return (
    <div className={classes.root}>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          margin: '0',
        }}
        container
        spacing={3}
      >
        {trainers.map((eachTrainer, index) => (
          <Zoom
            in={index + 1}
            style={{
              transitionDelay: index * 100,
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Card className={classes.root}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      <img
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                        }}
                        src={eachTrainer.src}
                        alt="r"
                      />
                    </Avatar>
                  }
                  title={<b>{eachTrainer.name}</b>}
                  subheader={eachTrainer.position}
                />
                <CardMedia
                  className={classes.media}
                  image="photos\kotramichalplakat_przyciete.png"
                  title="Paella dish"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    style={{
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {eachTrainer.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Zoom>
        ))}
      </Grid>
    </div>
  );
};

export default Trenerzy;
