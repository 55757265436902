import React from 'react';
import { makeStyles, CssBaseline, Grid } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
  bonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  eachBonPhoto: {
    height: '400px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  turniej1: {
    backgroundImage: `url(/photos/turniej1xd.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    padding: '24px',
    imageRendering: '-webkit-optimize-contrast',
    // imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '400px',
    },
  },
  turniej2: {
    backgroundImage: `url(/photos/turniej2.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    padding: '24px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '300px',
    },
  },
  turniej3: {
    backgroundImage: `url(/photos/turniej3.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    padding: '24px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '300px',
    },
  },
  turniej4: {
    backgroundImage: `url(/photos/turniej4.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    padding: '24px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '300px',
    },
  },
  bonPhoto2: {
    backgroundImage: `url(/photos/naciąg.jpg)`,
    backgroundSize: '100% 100%',
    imageRendering: '-webkit-optimize-contrast',

    marginTop: '40px',
  },
  shopHeader: {
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  shopHeaderTurniej: {
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  shopContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
      textAlign: 'center',
    },
  },
  link: {
    textDecoration: 'underline',
    color: 'black',
    fontWeight: 'bold',
  },
}));
const Turnieje = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <div>
      <Grid container spacing={7}>
        <Grid item sm={12} lg={6}>
          <Slide
            in={1}
            style={{
              transitionDelay: 100,
            }}
          >
            <h3 className={classes.shopHeaderTurniej}>Turnieje</h3>
          </Slide>
          <p className={classes.shopContent}>
          Czy wiesz, że organizujemy turnieje w squasha? Rozgrywane są one na trzech kortach. Kalendarz 
          turniejów jest dostępny na{' '}
            <a
              className={classes.link}
              href="https://polskisquash.pl/rozgrywki/rozgrywki-indywidualne/kalendarz-turniejow/"
            >
              stronie Polskiego Związku Squasha
            </a>{' '}
            .Dodatkowo informujemy o najbliższym 
turnieju squasha na naszym Facebooku i Instagramie, z minimum 2 tygodniowym wyprzedzeniem.
          </p>
        </Grid>
        <Grid item sm={12} lg={6}>
          <Slide
            in={1}
            style={{
              transitionDelay: 100,
            }}
          >
            <h3 className={classes.shopHeader}>System</h3>
          </Slide>
          <p className={classes.shopContent}>
            Na turniejach towarzyszy nam system TournamentTools. Wyniki są
            wyświetlane online na stronie internetowej, telewizorze i tabletach
            do sędziowania. Wyniki oraz statystyki można śledzić w każdej
            chwili. Dbamy, aby każdy turniej był zorganizowany dla Was na
            najwyższym poziomie.
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={7}>
        <Slide
          in={1}
          style={{
            transitionDelay: 200,
          }}
          direction="right"
        >
          <Grid style={{ padding: '24px', width: '100%' }} item sm={12} lg={6}>
            <div className={classes.turniej1} />
          </Grid>
        </Slide>
        <Slide
          in={1}
          style={{
            transitionDelay: 300,
          }}
          direction="left"
        >
          <Grid style={{ padding: '24px', width: '100%' }} item sm={12} lg={6}>
            <div className={classes.turniej2} />
          </Grid>
        </Slide>
        <Slide
          in={1}
          style={{
            transitionDelay: 400,
          }}
          direction="right"
        >
          <Grid style={{ padding: '24px', width: '100%' }} item sm={12} lg={6}>
            <div className={classes.turniej3} />
          </Grid>
        </Slide>
        <Slide
          in={1}
          style={{
            transitionDelay: 500,
          }}
          direction="left"
        >
          <Grid style={{ padding: '24px', width: '100%' }} item sm={12} lg={6}>
            <div className={classes.turniej4} />
          </Grid>
        </Slide>
      </Grid>
    </div>
  );
};

export default Turnieje;
