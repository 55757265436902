import React from 'react';
import { makeStyles, CssBaseline, Grid } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
  logotypy: {
    width:'60%',
    height:'100px',
    backgroundSize:'100%',
    background: `url(/photos/logotypy.png) no-repeat`,
    [theme.breakpoints.down('md')]: {
      width:'100%',
      height:'120px'
    },
  }
}));
const Turnieje = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <div>
      <div className={classes.logotypy}></div>
      <h2>Sfinansowano w ramach reakcji Unii na pandemię COVID-19 </h2>
      <h4>Realizuję projekt dofinansowany z Funduszy Europejskich z Programu Operacyjnego Inteligentny Rozwój 

Oś priorytetowa 6 Zwiększenie potencjału przedsiębiorstw i przygotowanie 

do cyfrowej transformacji w kontekście pandemii COVID-19 

działanie 6.2 Wsparcie MŚP w obszarze cyfryzacji - Bony na cyfryzację 

  pt. „Zakup i wdrożenie nowoczesnych technologii cyfrowych w postaci oprogramowania dostarczającego innowacyjne 

rozwiązania w zakresie squasha (trening, gry) oraz zarządzania klubem sportowym (CRM).” </h4>
<h4>Cel projektu:</h4><span>Dostosowanie się do nowych standardów informatycznych oraz wprowadzanie innowacyjnych rozwiązań technologicznych. Oczekiwane korzyści z realizacji projektu to zwiększenie konkurencyjności, zwiększenie asortymentu usług, optymalizacja zarządzania przedsiębiorstwem (moduł CRM), wzmocnienie odporności Wnioskodawcy na wypadek wystąpienia kolejnych podobnych kryzysów do pandemii COVID-19</span>
<h4>Planowane efekty: </h4><span>
Realizacja projektu pozwoli na zaspokojenie potrzeb jakie identyfikuje Wnioskodawca w związku z wystąpieniem pandemii COVID-19: 

-zapewnienie bezpieczeństwa zdrowotnego zarówno klientom jak i pracownikom klubu w sytuacji występowania chorób zakaźnych u ludzi; 

-zapewnienie klientom nowych rozwiązań w zakresie prowadzenia rozgrywki na korcie do squasha, które będą dla nich atrakcyjne; 

-zachęcenie dotychczasowych klientów firmy, którzy przerwali treningu z powodu pandemii do powrotu do klubu; 

-pozyskanie grona nowych klientów; 

-usprawnienie działalności klubu pod kątem administracji, zarządzania i marketingu. 

  
</span>
<h4>Termin realizacji: 	01.06.2022 – 31.05.2023		 </h4>
<h4>Wartość projektu: 	299 545,00 zł		 </h4>
<h4>Kwota dofinansowania:  254 613,25 zł 	 </h4>

    </div>
  );
};

export default Turnieje;
