import React from 'react';
import axios from 'axios';
import { makeStyles, Grid, TextField } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    eachDate: {
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e5e5e5'
        }
    },
    backToListButton: {
        marginTop: '25px',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    inputEmail: {
        margin:'20px',
        '& > div': {
            borderBottom: 'none !important',
        }
    },
    header: {
        [theme.breakpoints.down('sm')]: {
            padding:'0px 20px',
            textAlign:'center',
          },
    },
    shopHeader: {
        color: '#333333',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      shopContent: {
        display:'flex',
        flexDirection: 'column',
        justifyContent:'space-between',
        [theme.breakpoints.down('sm')]: {
          padding: '24px',
          textAlign: 'center',
        },
      },
      photo: {
        backgroundImage: `url(/photos/squash-trening-rzeszow-dla-dzieci-kolonie-ferie-zimowe.jpg)`,
        imageRendering: '-webkit-optimize-contrast',
        backgroundSize: '100% 100%',
        width:'90%',
        height:'100%'
      },
      photo1: {
        backgroundImage: `url(/photos/tournamenttools-rezerwacje-squash-online-rzeszow-poznaj-squasha-darmowe-treningi.png)`,
        imageRendering: '-webkit-optimize-contrast',
        backgroundSize: '100% 100%',
        width:'90%',
        height:'100%',
        marginTop:'20px'
      },
      photo3: {
        backgroundImage: `url(/photos/serwis-rakiety-kolonie-rzeszow-squash-kawa.jpg)`,
        imageRendering: '-webkit-optimize-contrast',
        backgroundSize: '100% 100%',
        width:'90%',
        height:'100%',
        marginTop:'20px'
      },
      photo4: {
        backgroundImage: `url(/photos/kolonie-rzeszow-squash-dladzieci-zajecia-serwis-rakiet.jpg)`,
        imageRendering: '-webkit-optimize-contrast',
        backgroundSize: '100% 100%',
        width:'90%',
        height:'100%',
        marginTop:'20px'
      },
      photoContainer: {
        display:'flex',
        justifyContent:'center',
        width:'100%',
        height:'600px',
      }
  }));

const apiGetRequest = async (endpoint, paramsObj = {}) => {
    try {
      return await axios.get(`http://squash-akademia-pracownicy.pl/${endpoint}`, {
        params: paramsObj,
      });
    } catch (error) {
      return error.response;
    }
  };

  const apiPostRequest = async (endpoint, paramsObj = {}) => {
    try {
      return await axios.post(`http://squash-akademia-pracownicy.pl/${endpoint}`, paramsObj);
    } catch (error) {
      return error.response;
    }
  };


const individualClasses = () => {
    const [availableDates, setAvailableDates] = React.useState([]);
    const [pickedDate, setPickedDate] = React.useState(null);
    const [reservedBy, setReservedBy] = React.useState('');
    const [reservedByPhone, setReservedByPhone] = React.useState('');
    const classes = useStyles();
    const getAvailableDates = async () => {
        const result = await apiGetRequest('reservation/availableDates')
        if (result.status === 200)
            setAvailableDates(result.data);
    }

    const parseDate = (date) => {
        const dateTime = date.split('T')[0];
        const hour = date.split('T')[1].split(':')[0];
        return `${dateTime} ${hour}:00`;
    };
    const emailRegexp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const phoneRegexp = /^\d{9}$/
    React.useEffect(() => {
        getAvailableDates()
    }, [])

    const handleBookDate = async () => {
        const result = await apiPostRequest('reservationToConfirm/add',  {...pickedDate, reservedBy, reservedByPhone})
        if (result.status === 201) {
            setReservedBy('');
            setReservedByPhone('')
            setPickedDate(null);
        }
    }

    const naviatgeToCennik = () => {

    }

    return (<Grid container style={{display:'flex', alignItems:'stretch'}}>
        <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Zajęcia dla dzieci</h3>
        </Slide>
        <div className={classes.shopContent}>
        <p style={{flexGrow: 1}}>
        Masz malucha, który uwielbia sport!? Squash to idealna gra dla każdego! Nasz trener Janek uczy gry w 
squasha dzieci już od 5 roku życia! Jego podejście oraz ciekawe zajęcia zajmą każde dziecko!<br></br>
Organizujemy dla Waszych pociech zajęcia grupowe w trakcie wakacji oraz w czasie ferii! Zajęcia są 
prowadzone przez naszego trenera Janka, dla maksymalnie 18 dzieci. Dla każdego dziecka na okres zajęć 
przygotowujemy odpowiednie rakiety i okulary ochronne
        </p>
        </div>
        <div className={classes.photoContainer}>
        <div className={classes.photo}></div>
        </div>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Poznaj squasha</h3>
        </Slide>
        <div className={classes.shopContent}>
        <p style={{flexGrow: 1}}>
        W związku z wieloma pytaniami przygotowaliśmy darmowe zajęcia grupowe dla osób początkujących! 
Organizujemy je dla Was w każdy piątek o godzinie 18:00. Zapisz się dzwoniąc do nas pod numer 533 
330 100 lub napisz na Facebook lub Instagram. Zabierz swojego przyjaciela i pokochajcie ten sport 
razem! Gwarantujemy swobodną atmosferę i porządny trening squasha!
        </p>
        </div>
        <div className={classes.photoContainer}>
        <div className={classes.photo1}></div>
        </div>
      </Grid>
      {/* <Grid style={{height:'600px'}} item xs={12} sm={12} lg={6}>
          <div className={classes.photo}></div>
      </Grid>
      <Grid item sm={12} lg={6}>
        <div className={classes.photo1}></div>
      </Grid> */}
      <Grid xs={12}>
            <p className={classes.shopContent}>Chcesz nauczyć się grać w squasha? A może potrzebujesz podszkolić swoje dotychczasowe umiejętności? 
            Nauczymy Cię grać w squasha! Wybierz termin i zapisz się na lekcje z naszym trenerem!<br></br>
            Możesz zapisać się sam lub zabrać bliską Ci osobę! Na lekcji z trenerem mogą być maksymalnie dwie 
osoby <br></br>
            Nie masz sprzętu do gry w squasha? Nic nie szkodzi! Na lekcje z trenerem zapewniamy Ci rakietę, 
okulary ochronne oraz piłeczkę!<br></br>
            Co musisz zabrać ze sobą? Strój sportowy oraz obowiązkowo obuwie z podeszwą non marking. To 
wszystko!<br></br>
            Pamiętaj! Rezerwując lekcje z trenerem nie zapomnij wynająć kortu na tą samą godzinę. Cennik kortu do 
gry w squasha znajdziesz                         <Link
                          className={classes.linkStyle}
                          to="/squash-rzeszow-cennik"
                        >tutaj</Link>
                        <br></br>
            Dla kogo są lekcje z trenerem!? Dla każdego! Jeśli zaczynasz przygodę ze squashem, warto wykupić sobie 
godzinę lekcji na początek. Trener przedstawi Ci ważne zagadnienia, nauczy Cię grac od podstaw. 
Zaczynając naukę samemu możesz narazić się na złe nawyki, które później będą problematyczne do 
oduczenia. Podczas lekcji z trenerem masz pewność, że nauczy Cię grac odpowiednio, aby gra sprawiała 
Ci ogromną radość!<br>
</br>Nawet jeśli już grasz w squasha warto wybrać się na lekcję z trenerem! Sprawdzi on Twoją grę i z 
pewnością ją udoskonali!
            </p>
        </Grid>
        <Grid item xs={0} sm={12} lg={6}>
            <div className={classes.photoContainer}>
        <div className={classes.photo3}></div>
        </div>
            </Grid>
            <Grid xs={0} item sm={12} lg={6}>
            <div className={classes.photoContainer}>
        <div className={classes.photo4}></div>
        </div>
</Grid>
        {!!availableDates.length && !pickedDate && (
            <>
            <Grid className={classes.header} item xs={12}>
                <h3>Wybierz datę zajęć indywidualnych ktore chcesz zarezerwować.</h3>
            </Grid>
            {availableDates.map((date, index) => (
            <Grid onClick={(() => setPickedDate(date))} className={classes.eachDate} item lg={3} md={6} xs={12}>
                        <p>{index+1}<b>{`. ${date.user.firstName} ${date.user.lastName} `}</b>{parseDate(date.startDate)}</p>
            </Grid>
        ))}
            </>
            )}
        {pickedDate && (
            <Grid style={{textAlign: 'center'}} item xs={12}>
                <h3>{`Zajęcia odbędą się z ${pickedDate.user.firstName} ${pickedDate.user.lastName} w dniu ${parseDate(pickedDate.startDate).split(' ')[0]} o godzinie ${parseDate(pickedDate.startDate).split(' ')[1]}`}</h3>
                <Grid item xs={12}>
                <p>Wprowadź email do rezerwacji terminu.</p>
                <TextField placeholder='EMAIL' error={reservedBy && !emailRegexp.test(reservedBy)} helperText={reservedBy && !emailRegexp.test(reservedBy) && 'Ten email jest niepoprawny'}  onChange={(e) => setReservedBy(e.target.value)} className={classes.inputEmail}></TextField>
                <TextField type="number" placeholder="telefon" error={reservedByPhone && !phoneRegexp.test(reservedByPhone)} helperText={reservedByPhone && !phoneRegexp.test(reservedByPhone) && 'Ten telefon jest niepoprawny'}  onChange={(e) => setReservedByPhone(e.target.value)} className={classes.inputEmail}></TextField>
                </Grid>
                <Grid item xs={12}>
                <h3 className={classes.backToListButton} onClick={() => handleBookDate()}>Rezerwuj</h3>

                </Grid>

                <span className={classes.backToListButton} onClick={() => setPickedDate(null)}>Wróć do widoku listy.</span>
            </Grid>
        )}
    </Grid>)
}

export default individualClasses;