import './App.css';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import Navbar from './components/navbar/Navbar';
import RouterComponent from './router';
import Footer from './components/Footer/Footer';
import { Link } from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";

import themeForMe from './theme';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 150px)',
    '& > *': {
      color: '#424242',
    },
  },
  contentContainer: {
    width: '100%',
  },
  contentDivider: {
    width: '40%',
    height: '1px',
    margin: '70px auto',
    backgroundColor: 'black',
  },
  lolz: {
    width: '70%',
    margin: '100px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '20px auto',
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    },
  },
  uniaInfo: {
    backgroundImage: `url(/photos/unia.png)`,
    width:'160px',
    height: '100px',
    display:'flex',
    justifyContent: 'center',
    margin: 'auto 20px auto auto',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      margin: '0px auto',
    },
  },
}));
function App() {
  const classes = useStyles();
  const path = useLocation();
  const history = useHistory ();
  const [slideIn, setSlideIn] = React.useState(false);
  React.useEffect(() => {
    setSlideIn(true);
  }, []);
  const onClick= () => {
    history.push ('./Unia');
  };
  const [navbarItems, setNavbarItems] = React.useState([
    {topic: 'Aktualności',
  url: 'o-nas-akademia-squash-rzeszow'},
  {topic: 'Cennik',
  url: 'squash-rzeszow-cennik'},
  {topic: 'Trenerzy',
  url: 'squash-z-trenerem-squash-rzeszow'},
  {topic: 'Turnieje',
  url: 'squash-rzeszow-turniej-squasha'},
  {topic: 'Rezerwacje',
  url: 'squash-rzeszow-rezerwacje'},
  {topic: 'Liga',
  url: 'squash-rzeszow-liga-squash'},
  {topic: 'Serwis',
  url: 'squash-rzeszow-rakiety-do-gry-serwis-rakiet'},
  {topic: 'Sklep',
  url: 'squash-rzeszow-sklep'},
  {topic: 'Kontakt',
  url: 'squash-rzeszow-kontakt'},
  {topic: 'Lekcje indywidualne',
  url: 'squash-rzeszow-lekcje-indywidualne-squash-z-trenerem'},
  ]);
  console.log(path.pathname.split('/')[1].charAt(0).toLowerCase() +
  path.pathname.split('/')[1].slice(1));
  return (
    <>
      <MuiThemeProvider theme={themeForMe}>
        <div className={classes.root}>
          <div className={classes.contentContainer}>
            <Navbar />
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div onClick={onClick} className={classes.uniaInfo} />
              <h1 className={classes.title} style={{ color: '#333333' }}>
                {path.pathname === '/'
                  ? 'U nas zagrasz w squasha!'
                  : navbarItems.find(item => item.url === path.pathname.split('/')[1].charAt(0).toLowerCase() +
                  path.pathname.split('/')[1].slice(1))?.topic}
              </h1>
            </div>
            <div className={classes.lolz}>
              <RouterComponent />
            </div>
          </div>
        </div>
        <Footer />
      </MuiThemeProvider>
    </>
  );
}

export default App;
