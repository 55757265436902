import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '150px',
    width: '100%',
    backgroundColor: 'black',
    color: 'white',
  },
  eachContactItem: {
    textAlign: 'center',
    lineHeight: '50px',
  },
  contactHeader: {
    textAlign: 'center',
    lineHeight: '50px',
    fontWeight: '700',
    fontSize: '24px',
  },
  instagramIcon: {
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    color: '#2E3133',
  },
  facebookIcon: {
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    color: '#2E3133',
  },
  instagramIconText: {
    color: '#2E3133',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  gridFacebook: {
    '&:hover': {
      color: '#3b5999',
      cursor: 'pointer',
      '& $facebookIconText': {
        color: '#3b5999',
        cursor: 'pointer',
      },
      '& $facebookIcon': {
        color: '#3b5999',
        cursor: 'pointer',
      },
    },
  },
  facebookIconText: {
    color: '#2E3133',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  gridInstagram: {
    '&:hover': {
      color: '#e4405f',
      cursor: 'pointer',
      '& $instagramIconText': {
        color: '#e4405f',
        cursor: 'pointer',
      },
      '& $instagramIcon': {
        color: '#e4405f',
        cursor: 'pointer',
      },
    },
  },
  // FacebookIcon: {
  //   height: '48px',
  //   width: '48px',
  //   borderRadius: '50%',
  //   color: '#2E3133',
  //   '&:hover': {
  //     color: '#3b5999',
  //     cursor: 'pointer',
  //   },
  // },
  regulaminContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#2E3133',
    fontWeight: '600',
  },
  linkStyle: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  '@keyframes myEffect': {
    '0%': {
      color: '#2E3133',
    },
    '50%': {
      color: '#FF4136',
    },
    '100%': {
      color: '#2E3133',
    },
  },
  regulamin: {
    animation: `$myEffect 3000ms infinite`,
  },
}));
const Serwis = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <div className={classes.root}>
      <Grid container style={{ height: '100%' }}>
        <Grid className={classes.regulaminContainer} item xs={12} sm={3}>
          <Link className={classes.linkStyle} to="Regulamin">
            <span className={classes.regulamin}>Regulamin</span>
          </Link>
        </Grid>
        <Grid item xs={0} sm={5} />
        <Grid item container xs={12} sm={4}>
          <Grid item sm={6} xs={12} />
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
            className={classes.gridFacebook}
            item
            sm={3}
            xs={6}
          >
            <FacebookIcon
              onClick={() =>
                window.open(
                  'https://www.facebook.com/klubsquashakademia',
                  '_blank'
                )
              }
              className={classes.facebookIcon}
            />
            <span className={classes.facebookIconText}>Polub nas!</span>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
            className={classes.gridInstagram}
            item
            sm={3}
            xs={6}
          >
            <InstagramIcon
              onClick={() =>
                window.open(
                  'https://www.instagram.com/klub_squashakademia/',
                  '_blank'
                )
              }
              className={classes.instagramIcon}
            />
            <span className={classes.instagramIconText}>Obserwuj nas!</span>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Serwis;
