import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  shopHeader: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  shopContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
      textAlign: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold',
  },
  liga1: {
    backgroundImage: `url(/photos/liga-damska-meska-rzeszow-squash-trening-grupowy.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    padding: '24px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '200px',
    },
  },
  liga2: {
    backgroundImage: `url(/photos/koszulki-cutline-rzeszow-squash-serwis-sklep-kawa.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    padding: '24px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '200px',
    },
  },
  containerPhoto: {
    padding: '24px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
}));
const Liga = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <div>
      <p className={classes.shopContent}>
      W naszej squash akademii mamy dwie ligi: damską i męską. Rozgrywki odbywają się w każde piątkowe 
        popołudnie. Liga damska trwa w godzinach od 18:00 do 20:00, natomiast męska od 20:00 do 22:00. Jeśli 
        chcesz wziąć udział w lidze, wystarczy zapisać się na naszej grupie na Facebooku. Zachęcamy każdego 
        gracza do wzięcia udziału w tej sportowej rywalizacji! Udział w lidze może wziąć każdy, na każdym 
        poziomie gry{' '}
      </p>
      <p className={classes.shopContent}>
      Zapisy do ligi trwają do środy. Jeśli zapiszesz się, ale zrezygnujesz z udziału, musisz opłacić swoją 
        nieobecność! Koszt ligii rozkłada się na wszystkich uczestników. Dodatkowo podczas płatności można 
        odbić kartę Multisport/Medicover. {' '}
      </p>
      <p className={classes.shopContent}>Nasz klub reprezentują dwie drużyny w Polskiej Lidze Squasha! Są to drużyny:</p>
      <p className={classes.shopContent}>Squash Akademia Girls - Drużyna damska</p>
      <p className={classes.shopContent}>Squash Akademia - Drużyna męska</p>
      <p className={classes.shopContent}>Osoby reprezentujące nasz klub występują w klubowych koszulkach firmy Cutline!</p>

      <Grid container>
        <Slide
          in={1}
          style={{
            transitionDelay: 300,
          }}
          direction="left"
        >
          <Grid className={classes.containerPhoto} item sm={12} lg={6}>
            <div className={classes.liga1} />
          </Grid>
        </Slide>
        <Slide
          in={1}
          style={{
            transitionDelay: 300,
          }}
          direction="right"
        >
          <Grid className={classes.containerPhoto} item sm={12} lg={6}>
            <div className={classes.liga2} />
          </Grid>
        </Slide>
      </Grid>
    </div>
  );
};

export default Liga;
