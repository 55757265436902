import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  newsRoot: {
    width: '90%',
    margin: '0 auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  root: {
    height: '500px',
    margin: '20px auto',
    position: 'relative',
    backgroundImage: 'url(/photos/squash_racquet.jpeg)',
    imageRendering: '-webkit-optimize-contrast',

    objectFit: '100% 100%',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      objectFit: '100% 100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      margin: '20px auto',
      width: '100%',
      height: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      objectFit: '100% 100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      margin: '20px auto',
      height: '250px',
    },
    '&:hover': {
      transitionDuration: '0.5s',
      cursor: 'pointer',
    },
  },
  infoHeader: {
    height: '100px',
    width: '100%',
    paddingTop: '10px',
    color: 'white',
    backgroundColor: 'black',
    opacity: '0.8',
  },
  getMore: {
    height: '50px',
    width: '100%',
    color: 'white',
    backgroundColor: 'black',
    opacity: '0.8',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  linkStyle: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  main1: {
    backgroundImage: `url(/photos/main1.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '200px',
    },
  },
  main2: {
    backgroundImage: `url(/photos/main2.jpg)`,
    backgroundSize: '100% 100%',
    height: '400px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
      height: '200px',
    },
  },
  headerContent: {
    fontSize: '36px',
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  headerContentWithImage: {
    fontSize: '36px',
    color: '#333333',
    filter: 'drop-shadow(2px 4px 6px black)',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  textContent: {
    textAlign: 'right',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    filter: 'drop-shadow(2px 4px 6px black)',
    paddingRight: '12px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
      textAlign: 'center',
    },
  },
  textContentSmaller: {
    textAlign: 'right',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    filter: 'drop-shadow(2px 4px 6px black)',
    paddingRight: '12px',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  gridStyle: {
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
    },
  },
  gridWithPhoto2: {
    width: '100%',
    height: '400px',
    backgroundImage: `url(/photos/godziny2.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
    padding: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  gridWithPhoto1: {
    width: '100%',
    height: '400px',
    backgroundImage: `url(/photos/godziny1.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
    padding: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  divider: {
    height: '1px',
    width: '60%',
    backgroundColor: 'black',
    margin: '-4px auto 20px auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
      textAlign: 'center',
      margin: '10px auto 20px auto',
    },
  },
  smallSizeOPhoto: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '200px',
      backgroundImage: `url(/photos/main3.jpg)`,
      imageRendering: '-webkit-optimize-contrast',
      backgroundSize: '100% 100%',
    },
  },
}));
const Main = () => {
  const [componentMount, setComponentMount] = React.useState(false);
  const classes = useStyles();
  const [news, setNews] = React.useState([
    { text: 'What is Lorem Ipsum', boxHovered: false },
    { text: 'Why do we use it?', boxHovered: false },
    { text: 'Where does it come from?', boxHovered: false },
    { text: 'Where can I get some?', boxHovered: false },
  ]);
  React.useEffect(() => {
    console.log(componentMount);
    setComponentMount(true);
  }, []);

  const handleEnter = (item, index) => {
    const myNews = [...news];
    myNews[index] = { ...item, boxHovered: true };
    setNews(myNews);
  };
  const handleLeave = (item, index) => {
    const myNews = [...news];
    myNews[index] = { ...item, boxHovered: false };
    setNews(myNews);
  };

  return (
    <div className={classes.newsRoot}>
      <Slide
        style={{
          transitionDelay: 100,
          overflowY: 'hidden',
        }}
        direction="left"
        in={componentMount}
      >
        <div className={classes.smallSizeOPhoto} />
      </Slide>
      <Slide
        style={{
          transitionDelay: 100,
          overflowY: 'hidden',
        }}
        direction="left"
        in={componentMount}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <p style={{ padding: '20px 24px', fontSize: '20px' }}>Poszukujesz nowej, ciekawej pasji? A może jesteś wielbicielem gry w squasha? Squash akademia w 
            Rzeszowie to miejsce dla Ciebie!</p>
        </div>
      </Slide>
      <Slide
        style={{
          transitionDelay: 100,
          overflowY: 'hidden',
        }}
        direction="right"
        in={componentMount}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <p style={{ padding: '20px 24px', fontSize: '20px' }}>
          Nasz klub znajduje się w samym sercu Rzeszowa. Znajdziesz nas w galerii handlowej Europa 2 nad 
          klubem Bajka przy al. Józefa Piłsudskiego 34. Dawno temu, w tym miejscu odbywały się dyskoteki, w 
          klubie muzycznym o nazwie AKADEMIA. Utarło się więc powiedzenie ,,idziemy do Akademii'', z którego 
          korzystamy do dziś umawiając się na <b>treningi squasha</b>!
          </p>
        </div>
      </Slide>
      <Slide
        style={{
          transitionDelay: 100,
          overflowY: 'hidden',
        }}
        direction="left"
        in={componentMount}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <p style={{ padding: '20px 24px', fontSize: '20px' }}>
          Czekamy na Was już od 6 rano! Do Waszej dyspozycji są trzy korty oraz szatnia damska i męska. Istnieje 
          możliwość zakupu zajęć z trenerami oraz wypożyczenie rakiety do <b>gry w squasha</b>. Ponadto codziennie 
          serwujemy dla Was pyszne, gorące napoje, shake białkowe oraz ciepłe przekąski!

          </p>
        </div>
      </Slide>
      <Grid container>
        <Slide
          style={{
            transitionDelay: 200,
            overflowY: 'hidden',
          }}
          direction="left"
          in={componentMount}
        >
          <Grid item lg={6} xs={12} className={classes.gridStyle}>
            <div className={classes.main1} />
          </Grid>
        </Slide>
        <Slide
          style={{
            transitionDelay: 200,
            overflowY: 'hidden',
          }}
          direction="right"
          in={componentMount}
        >
          <Grid item lg={6} xs={12} className={classes.gridStyle}>
            <div className={classes.main2} />
          </Grid>
        </Slide>
      </Grid>
    </div>
  );
};
export default Main;
