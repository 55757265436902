import React from 'react';
import { makeStyles, CssBaseline, Grid } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
  bonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  eachBonPhoto: {
    height: '400px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bonPhoto1: {
    backgroundImage: `url(/photos/voucher.png)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto2: {
    backgroundImage: `url(/photos/squash-box-prezent-serwis-trener-rzeszow.png)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto3: {
    backgroundImage: `url(/photos/koszulka.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto4: {
    backgroundImage: `url(/photos/kubek.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto5: {
    backgroundImage: `url(/photos/damska.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto6: {
    backgroundImage: `url(/photos/meska.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto7: {
    backgroundImage: `url(/photos/sklep-koszulka.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
    marginTop:'65px'
  },
  shopHeader: {
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  shopContent: {
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
      textAlign: 'center',
    },
  },
}));
const Sklep = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <Grid container spacing={7}>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Bon podarunkowy</h3>
        </Slide>
        <p className={classes.shopContent}>
                  Poszukujesz idealnego prezentu dla osoby uwielbiającej sport!? Może masz w rodzinie wielbiciela 
          squasha?! Nasz bon podarunkowy to najlepszy prezent dla takiej osoby!<br></br>
          Oferujemy możliwość wyboru - na jaka kwotę ma być bon oraz na jaką usługę można go wykorzstać! 
          Obdarowana osoba może przeznaczyć go na wynajem kortu, trening squasha z trenerem czy zakup 
          sprzętu sportowego!<br></br>
          Bon podarunkowy możesz nabyć w recepcji naszego squasha lub droga mailową (po wcześniejszym 
          opłaceniu wartości bonu przelewem bankowym). W naszej ofercie posiadamy również bony 
          podarunkowe w specjalnej, świątecznej oprawie. Nabyć je możesz u nas przez cały okres Świąt Bożego 
          Narodzenia!<br></br>
          Bonu podarunkowego nie można wymienić na gotówkę ani nie podlega zwrotom.
        </p>
        <div className={classes.bonContainer}>
          <Zoom
            in={1}
            style={{
              transitionDelay: 100,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto2}`} />
          </Zoom>
          <Zoom
            in={2}
            style={{
              transitionDelay: 200,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto1}`} />
          </Zoom>
        </div>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Sklep</h3>
        </Slide>
        <p className={classes.shopContent}>
        W naszym ,,małym, wielkim'' sklepie otrzymasz wszystko, czego potrzebujesz do gry w squasha! Możesz 
zaopatrzyć się w sportowe ubrania oraz niezbędne akcesoria takie jak: okulary ochronne, owijki czy piłki! 
Jeśli nie mamy tego, czego szukasz stacjonarnie, zamówimy to dla Ciebie! Składamy zamówienia na 
stronie{' '}
          <a style={{color: '#424242'}} href="https://www.erakiety.com">www.erakiety.com</a>.Przygotujemy dla Ciebie spory rabat na zakupy na tej stronie. Zapewniamy, 
że produkty, które zakupiłeś, otrzymasz ekspresowo! Czas oczekiwania na zamówienie to maksymalnie 
trzy dni robocze!
{' '}
        </p>
        <div className={classes.bonContainer}>
          <Zoom
            in={3}
            style={{
              transitionDelay: 300,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto7}`} />
          </Zoom>
        </div>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Koszulki klubowe</h3>
        </Slide>
        <div className={classes.bonContainer}>
          <Zoom
            in={1}
            style={{
              transitionDelay: 100,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto5}`} />
          </Zoom>
          <Zoom
            in={2}
            style={{
              transitionDelay: 200,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto6}`} />
          </Zoom>
        </div>
        <p>
        Chcesz mieć koszulke z logo Squash Akademii? Skontaktuj się z nami! W ofercie posiadamy koszulki 
damskie oraz męskie! Idealne na każdy turniej oraz trening z przyjaciółmi na naszym squashu!{' '}
        </p>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Squash box</h3>
        </Slide>
        <div className={classes.bonContainer}>
          <Zoom
            in={3}
            style={{
              transitionDelay: 300,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto4}`} />
          </Zoom>
        </div>
        <p className={classes.shopContent}>
        Nie masz pomysłu na oryginalny prezent? Podczas <b>treningu w squasha</b> możesz zakupić dla bliskiej Ci 
osoby squash box! Jest to wyjątkowy prezent dla kazdego fana tego sportu, przygotowany z dbałością o 
każdy detal. W skład boxu wchodzą: piłka, owijka, frotka na rękę lub głowę oraz kubek z naszym logo! 
Praktyczny prezent dla każdego gracza, który jest dla Was zawsze dostępny w naszej recepcji! 
        </p>
      </Grid>
    </Grid>
  );
};

export default Sklep;
