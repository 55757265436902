import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { IoIosArrowForward } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({}));
const Czwarty = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <div>
      <p>JESTEM Czwartym XD</p>
    </div>
  );
};

export default Czwarty;
