import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Zoom from '@material-ui/core/Zoom';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import RoomIcon from '@material-ui/icons/Room';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: '200',
    backgroundColor: 'white',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      position: 'static',
    },
  },
  stickyRoot: {
    width: '100%',
    height: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: '200',
    backgroundColor: 'white',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      position: 'static',
    },
  },
  logoContainer: {
    width: '300px',
    height: '100%',
    top: '80px',
    left: 40,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'static',
    },
  },
  logo: {
    height: '90%',
    transform: 'rotate(90deg)',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      height: '60%',
      marginTop: '10px',
      width: '70%',
      objectFit: 'contain',
    },
  },
  navbarContainer: {
    width: '80%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  stickyNavbarContainer: {
    width: '80%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'sticky',
    top: 0,
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
  },
  titleContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      width: '40%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      color: 'rgb(51, 51, 51)',
      paddingLeft: '10px',
    },
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  eachGridItem: {
    textAlign: 'center',
    color: '#2E3133',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    paddingBottom: '10px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  eachGridItemText: {
    textDecoration: 'none',
    position: 'relative',
    '&:hover': {
      fontWeight: '700',
    },
  },
  linkStyle: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  menuHamburger: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginRight: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
    },
  },
  expandableMenuItem: {
    color: '#2E3133',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
  },
  collapseMenu: {
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  contactContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    width: '100%',
    height: '120px',
    top: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  },
  topNavbarContainer: {
    width: '100%',
    height: '120px',
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: '120px',
    },
  },
  icon: {
    color: 'white',
    fontSize: '24px',
    marginRight: '10px',
  },
  iconTextOpenedHours: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    marginRight: '20px',
  },
  iconText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      margin: '5px 0px',
      width: '100%',
    },
  },
  stickyLogoContainer: {
    width: '300px',
    height: '100%',
    top: '0px',
    left: 40,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s ease-in',

    [theme.breakpoints.down('md')]: {
      position: 'static',
      width: '30%',
    },
  },
  stickyLogoIcon: {
    height: '50%',
    transform: 'rotate(90deg)',
    transition: 'all 0.2s ease-in',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      height: '50%',
      marginTop: '10px',
      width: '50%',
      objectFit: 'contain',
    },
  },
  lol: {
    marginBottom: '0px',
  },
  lol1: {
    marginBottom: '132px',
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const lol = 'it is a navbar';
  // const [navbarItems, setNavbarItems] = React.useState([
  //   {topic: 'Aktualności',
  // url: 'squash-rzeszow-cennik'},
  //   'Cennik',
  //   'Trenerzy',
  //   'Turnieje',
  //   'Rezerwacje',
  //   'Liga',
  //   'Serwis',
  //   'Sklep',
  //   'Kontakt',
  //   'lekcje-indywidualne',
  // ]);
  const [navbarItems, setNavbarItems] = React.useState([
    {topic: 'Aktualności',
  url: 'o-nas-akademia-squash-rzeszow'},
  {topic: 'Cennik',
  url: 'squash-rzeszow-cennik'},
  {topic: 'Trenerzy',
  url: 'squash-z-trenerem-squash-rzeszow'},
  {topic: 'Turnieje',
  url: 'squash-rzeszow-turniej-squasha'},
  {topic: 'Rezerwacje',
  url: 'squash-rzeszow-rezerwacje'},
  {topic: 'Liga',
  url: 'squash-rzeszow-liga-squash'},
  {topic: 'Serwis',
  url: 'squash-rzeszow-rakiety-do-gry-serwis-rakiet'},
  {topic: 'Sklep',
  url: 'squash-rzeszow-sklep'},
  {topic: 'Kontakt',
  url: 'squash-rzeszow-kontakt'},
  {topic: 'Lekcje indywidualne',
  url: 'squash-rzeszow-lekcje-indywidualne-squash-z-trenerem'},
  ]);
  const [navCase, setNavCase] = React.useState(false);
  const [menuSlide, setMenuSlide] = React.useState(false);
  React.useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 120;
      if (show) {
        setNavCase(true);
      } else {
        setNavCase(false);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={!navCase ? classes.lol : classes.lol1}>
      <div className={classes.topNavbarContainer}>
        <div className={classes.contactContainer}>
          <Grid container>
            <Grid className={classes.gridItem} item lg={2} md={3} sm={12}>
              <RoomIcon className={classes.icon} />
              <span className={classes.iconText}>
                Rzeszów al. Józefa Piłsudskiego 34
              </span>
            </Grid>
            <Grid className={classes.gridItem} item lg={2} md={3} sm={12}>
              <PhoneAndroidIcon className={classes.icon} />
              <span className={classes.iconText}>+48 533 330 100</span>
            </Grid>
            <Grid item lg={4} md={1} sm={12} />
            <Grid className={classes.gridItem} item lg={4} md={5} sm={12}>
              <QueryBuilderIcon className={classes.icon} />
              <span className={classes.iconTextOpenedHours}>
                Jesteśmy otwarci: pn-pt 6:00-23:00, sob-nd 9:00-21:00
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
      <AppBar
        style={{
          boxShadow: 'none',
          backgroundColor: '#FFFFFF',
        }}
        position={!navCase ? 'static' : 'fixed'}
      >
        <div className={!navCase ? classes.root : classes.stickyRoot}>
          <Toolbar
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px',
            }}
          >
            <div
              className={
                !navCase ? classes.logoContainer : classes.stickyLogoContainer
              }
            >
              <img
                src="/photos/squash-akademia.svg"
                className={!navCase ? classes.logo : classes.stickyLogoIcon}
                alt="logo"
              />
            </div>
            {navCase && (
              <div className={classes.titleContainer}>
                <h3 style={{ textAlign: 'center', width: '100%' }}>
                  Squash Akademia
                </h3>
              </div>
            )}
            <div
              className={
                !navCase
                  ? classes.navbarContainer
                  : classes.stickyNavbarContainer
              }
            >
              <Grid className={classes.gridContainer} container>
                {navbarItems.map((eachOptionItem, index) => (
                  <Zoom
                    in={index + 1}
                    style={{
                      transitionDelay: index * 100,
                    }}
                  >
                    <Grid className={classes.eachGridItem} item xs={1}>
                      <span className={classes.eachGridItemText}>
                        <Link
                          className={classes.linkStyle}
                          to={
                            eachOptionItem === 'Aktualności'
                              ? '/'
                              : `/${eachOptionItem.url.toLowerCase()}`
                          }
                        >
                          {eachOptionItem.topic}
                        </Link>
                      </span>
                    </Grid>
                  </Zoom>
                ))}
                <Grid
                  className={classes.menuHamburger}
                  item
                  xs={4}
                  md={1}
                  sm={2}
                >
                  <MenuIcon
                    onClick={() => setMenuSlide(!menuSlide)}
                    style={{
                      padding: '10px',
                      width: '50px',
                      height: '50px',
                      color: '#2E3133',
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Toolbar>
        </div>
        <div className={classes.collapseMenu}>
          <Collapse in={menuSlide}>
            {navbarItems.map((eachNavbarItem, index) => (
              <Zoom
                in={menuSlide}
                style={{
                  transitionDelay: index * 50,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    className={classes.linkStyle}
                    onClick={() => setMenuSlide(false)}
                    to={
                      eachNavbarItem === 'Aktualności'
                        ? '/'
                        : `/${eachNavbarItem.url.toLowerCase()}`
                    }
                  >
                    <p className={classes.expandableMenuItem}>
                      {eachNavbarItem.topic}
                    </p>
                  </Link>
                </div>
              </Zoom>
            ))}
          </Collapse>
        </div>
      </AppBar>
    </div>
  );
};

export default Navbar;
