import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));
const Rezerwacje = () => (
  <div>
    <iframe
      title="Rezerwacje"
      src="https://reservise.com/online/squashakademiarzeszow"
      style={{ width: '100%', height: '1050px', border: 0 }}
    />
  </div>
);

export default Rezerwacje;
