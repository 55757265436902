import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    width: '100%',
  },
  iconContainer: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    transition: 'color, background-color 0.2s',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      cursor: 'pointer',
    },
  },
  icon: {
    width: '40px',
    height: '40px',
  },
  eachGridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    width: '100%',
    fontWeight: '600',
  },
}));
const Kontakt = () => {
  const classes = useStyles();
  const lol = 'xD';

  return (
    <div className={classes.contactContainer}>
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          className={classes.eachGridItem}
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <div className={classes.iconContainer}>
            <HomeIcon className={classes.icon} />
          </div>
          <div
            style={{ textAlign: 'center' }}
            className={classes.textContainer}
          >
            <p>
              Nasze korty znajdują się przy al. Józefa Piłsudskiego 34 w
              Rzeszowie, wejście znajduje się przy galerii Europa II.
            </p>
          </div>
        </Grid>
        <Grid
          className={classes.eachGridItem}
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <div className={classes.iconContainer}>
            <PhoneAndroidIcon className={classes.icon} />
          </div>
          <div
            style={{ textAlign: 'center' }}
            className={classes.textContainer}
          >
            <p>Tel. 533 330 100</p>
          </div>
        </Grid>
        <Grid
          className={classes.eachGridItem}
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
        >
          <div className={classes.iconContainer}>
            <AccessTimeIcon className={classes.icon} />
          </div>
          <div
            style={{ textAlign: 'center' }}
            className={classes.textContainer}
          >
            <p>Godziny otwarcia</p>
            <p>Poniedziałek-Piątek, 6-23</p>
            <p>Sobota-Niedziela, 9-21</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Kontakt;
