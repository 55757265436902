import React from 'react';
import { makeStyles, CssBaseline, Grid } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
import { IoIosArrowForward } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
  bonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  eachBonPhoto: {
    height: '400px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bonPhoto1: {
    backgroundImage: `url(/photos/cennik-naciag.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
  },
  bonPhoto2: {
    backgroundImage: `url(/photos/naciagxd.jpg)`,
    imageRendering: '-webkit-optimize-contrast',
    backgroundSize: '100% 100%',
    marginTop: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },
  shopHeader: {
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  shopContent: {
    paddingRight:'5px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px',
      textAlign: 'center',
    },
  },
}));
const Serwis = () => {
  const classes = useStyles();
  const lol = 'xD';
  return (
    <Grid container>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Poszukujesz idealnej rakiety do gry dla siebie?</h3>
        </Slide>

        <p className={classes.shopContent}>
        Pomożemy Ci w tym! Fachowo doradzamy naszym klientom w najlepszym wyborze bez dodatkowych 
          opłat!
        </p>
        <p className={classes.shopContent}>
        Chcesz przetestować rakietę, zanim ją zakupisz? Dajemy Ci, aż 60 minut na sprawdzenie jej! Jeśli 
        dokonasz zakupu rakiety u nas — nie ponosisz kosztów wynajmu rakiety!
        </p>
        <p className={classes.shopContent}>
        Uwaga! Jeśli nie zdecydujesz się na zakup podczas testowania rakiety, należy opłacić koszt jej wynajmu, 
        który wynosi 20zł/h.    
        </p>
        <p className={classes.shopContent}>
        Czy wiesz, że... Nie przetrzymujemy rakiet, tylko zamawaimy je na bieżąco dla Was? Daje to pewność, że 
        wybrana rakieta w przeciągu kilku dni dotrze do Ciebie i będzie nowa!    
        </p>
        <div className={classes.bonContainer}>
          <Zoom
            in={2}
            style={{
              transitionDelay: 200,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto1}`} />
          </Zoom>
        </div>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Slide
          in={1}
          style={{
            transitionDelay: 100,
          }}
        >
          <h3 className={classes.shopHeader}>Serwis rakiet</h3>
        </Slide>
        <p className={classes.shopContent}>
        Czy wiesz, że w naszej squash akademii masz możliwość skorzystania z serwisu rakiet?{' '}
        </p>
        <p className={classes.shopContent}>
        Posiadamy wieloletnie doświadczenie w serwisowaniu sprzętu do squasha. Robimy to z najwyższą 
starannością, dobierając naciąg oraz jego siłę do indywidalnych potrzeb klienta. Ponadto serwisujemy 
również dla Was <b>rakiety tenisowe</b>. {' '}
        </p>
        <div className={classes.bonContainer}>
          <Zoom
            in={3}
            style={{
              transitionDelay: 300,
            }}
          >
            <div className={`${classes.eachBonPhoto} ${classes.bonPhoto2}`} />
          </Zoom>
        </div>
      </Grid>
    </Grid>
  );
};

export default Serwis;
