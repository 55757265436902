import React from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    textField: {
        "& > div > textarea": {
            color: "black",
        },
        width: "100%",
        "@global": {
            "::-webkit-scrollbar": {
                width: "10px",
                height: "10px",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#51bcda",
                borderRadius: "13px",
            },
            "::-webkit-scrollbar-track": {
                background: "#ffffff",
                borderRadius: "15px",
                boxShadow: "inset -30px 30px 0px #f0f0f0",
            },
        },
    },
}));
const Regulamin = () => {
    const classes = useStyles();
    const lol = "xD";
    return (
        <div>
            <TextField
                className={classes.textField}
                style={{ width: "100%", color: "black" }}
                placeholder="Regulamin SQASH AKADEMIA
 
 
        §1
        Regulamin SQUASH AKADEMIA
        1.	Niniejszy regulamin określa zasady korzystania z usług oferowanych przez SQUASH-AKADEMIA w Rzeszowie, ( dalej: „Klub”) prowadzonego przez Jana Rocheckiego pod firmą Jan Rochecki Squash –Training – Events – Tournaments z siedzibą w Rzeszowie ul. Józefa Piłsudskiego 34a, 35-001 Rzeszów.
        2.	Dokonanie rezerwacji kortu, przebywanie na terenie Klubu oraz korzystanie z jego usług jest jednoznaczne z akceptacją przez osobę korzystającą z usług Klubu ( dalej: „Klient” ) poniższego regulaminu  i zobowiązania do stosowania jego postanowień.
        3.	 Klub jest czynny w godzinach  7:00-23:00 w dni powszednie oraz 9:00-20:00
         w soboty i niedziele.
        4.	Klient może korzystać z klubu na 15 minut przed otwarciem i zobowiązany jest do opuszczenia klubu najpóźniej do 30 minut po godzinach zamknięcia obiektu.
        5.	 Na terenie Klubu obowiązuje bezwzględne stosowanie się do zaleceń pracowników Klubu.
        6.	Dzieci do 13 roku życia mogą przebywać na terenie Klubu tylko pod opieką lub na odpowiedzialność swoich rodziców, lub pod opieką pełnoletnich opiekunów będących Klientami Klubu. Dzieci zobowiązane są do przestrzegania wszystkich zasad dotyczących pełnoletnich Klientów Klubu.
        7.	Osoba, nieposiadająca pełnej zdolności do czynności prawnych, która ukończyła 13 rok życia, może korzystać z Klubu za uprzednią pisemną zgodą przedstawiciela ustawowego.
        8.	Na terenie Klubu zabrania się wnoszenia i spożywania napojów alkoholowych, palenia tytoniu, wnoszenia oraz używania narkotyków, sterydów i innych środków o podobnym działaniu, wstępu do Klubu pod ich wpływem, handlu i akwizycji, naklejania i pozostawienia reklam, wnoszenia napojów w szklanych lub otwartych naczyniach.
        9.	Sprzęt i urządzenia Klubu należy używać zgodnie z ich przeznaczeniem i wskazaniami instruktora lub innego pracownika Klubu.
        10.	Karnet lub jednorazowa opłata upoważnia do otrzymania kluczyka do szafki, na czas przebywania w klubie. Po skorzystaniu z usługi kluczyk należy zwrócić w recepcji klubu.
         W przypadku zgubienia lub zniszczenia kluczyka należy wpłacić 20 zł na rzecz Klubu, z tytułu kosztu wymiany zamka.
        11.	Klient ma możliwość przechowywania swoich rzeczy w udostępnionej szafce tylko na czas pobytu w Klubie.
        12.	Rzeczy wartościowe należy złożyć bezwzględnie do depozytu znajdującego się w recepcji Klubu.
        13.	Klient ponosi pełną odpowiedzialność finansową za zniszczenia majątku Klubu spowodowane jego działaniem lub zaniechaniem.
        14.	Klub zastrzega sobie prawo do zawieszenia usług w terminach wynikających z przyczyn technicznych lub innych istotnych okoliczności o czym każdy Klient zostanie poinformowany.
        15.	Z oferty klubu mogą korzystać osoby bez przeciwwskazań zdrowotnych.
        16.	W sprawach nieokreślonych w Regulaminie decyzje podejmuje Manager Klubu SQUASH-AKADEMIA.
        17.	Klient posiada prawo do pisemnego odwołania się od decyzji do Właściciela klubu SQUASH-AKADEMIA.
        18.	Niniejszy Regulamin może ulec zmianie, o czym Klient zostanie poinformowany nie później niż na 14 dni kalendarzowych przed dokonaniem zmian.
        19.	Informacja o zmianie Regulaminu wraz z zestawieniem zmian zostanie opublikowana na stronie www.squash-akademia.pl.
        20.	W razie, gdy Klient, nie akceptuje nowej treści Regulaminu, obowiązany jest zawiadomić o tym fakcie Klub w ciągu 14 dni od daty opublikowania informacji o zmianie Regulaminu.
        21.	Wszelkie kary i opłaty przewidziane w niniejszym Regulaminie z tytułu naruszenia regulaminu lub wyrządzenia szkody Klubowi nie wyłączają prawa Klubu do dochodzenia od Klienta odszkodowania przewyższającego wysokość zastrzeżonej kary, do pełnej wysokości poniesionej szkody.
        
        
        §2
        ZASADY KORZYSTANIA Z KORTÓW DO SQUASHA:
        1.	Korty do squasha czynne są w godzinach otwarcia Klubu i wynajmowane są na pełne godziny zegarowe.
        2.	Dozwolone jest przedłużenie gry maksymalnie do 10 minut tylko i wyłącznie w przypadku wolnego kortu. Przedłużenie określonego czasu traktowane jest jako wynajęcie kortu na kolejną godzinę i opłacenie go zgodnie z obowiązującym cennikiem.
        3.	Podstawą do korzystania z kortów jest posiadanie karnetu lub uiszczenie jednorazowej opłaty przed wejściem na kort. Opłaty za kort dokonać można bezpośrednio w recepcji Klubu.
        4.	Wysokość opłat za korzystanie z kortu do squasha podana jest w cenniku dostępnym w recepcji Klubu oraz na stronie www.squash-akademia.pl.	
        5.	Zabrania się gry w nieodpowiednim obuwiu oraz pod wpływem alkoholu czy innych środków odurzających.
        6.	Wstęp na kort dozwolony jest tylko w zamiennym obuwiu nie pozostawiającym śladów na powierzchni kortu - obuwie z tzw. podeszwą non-marking przeznaczone do sportów halowych.
        7.	W przypadku niezastosowania zalecanego obuwia obsługa Klubu ma prawo odmówić wstępu na kort, co jest jednoznaczne z uiszczeniem opłaty za zarezerwowany kort.	
        8.	Zabrania się wnoszenia na kort i pozostawiania na jego podłodze zegarków, butelek, ręczników, telefonów komórkowych oraz wszelkich innych przedmiotów mogących zanieczyścić lub zniszczyć kort, utrudniać grę lub powodować zagrożenie zdrowia graczy na korcie.
        9.	W przypadku zniszczenia parkietu lub jego zabrudzenia poprzez korzystanie z niewłaściwego obuwia lub wnoszenia niedopuszczalnych przedmiotów klient ma obowiązek uregulować opłatę karną w wysokości 500 zł pokrywającą koszty usunięcia zniszczeń.
        10.	Rezerwacji kortu można dokonać za pośrednictwem strony www.squash-akademia.pl., telefonicznie, za pośrednictwem aplikacji służącej do rezerwacji kortów lub w recepcji Klubu.
        11.	Rezerwacja kortu na godzinę oznacza możliwość korzystania z niego przez 60 minut, liczone od pełnej godziny zegarowej.	
        12.	Korzystanie z kortów możliwe jest po uprzednim zapoznaniu się z zasadami gry.
        13.	W klubie istnieje możliwość wypożyczenia sprzętu sportowego: rakiet, piłeczek i okularów.
        14.	Przed rozpoczęciem gry zalecane jest wykonanie rozgrzewki.
        15.	Ze względów bezpieczeństwa osoby do 18 roku życia mają obowiązek gry w okularach ochronnych. Osobom pełnoletnim również zaleca się ich stosowanie.
        16.	Klub zastrzega sobie prawo do odwołania rezerwacji kortu do squasha Klienta w wypadku turnieju lub innego wydarzenia, jak również z przyczyn technicznych lub innych istotnych okoliczności, o czym Klient zostanie wcześniej poinformowany.	
        17.	Za wyrządzone przez Klienta szkody na mieniu oraz szkody na osobie odpowiedzialność ponosi Klient, a w przypadku małoletnich Klientów również ich przedstawiciele ustawowi, zgodnie z obowiązującymi przepisami.
        
        
        §3
        ZASADY KORZYSTANIA Z KARNETÓW ORAZ DOKONYWANIA STAŁYCH REZERWACJI:
        1.	 Stała rezerwacja dokonywana jest bezterminowo, Klient może zrezygnować ze swojej stałej rezerwacji w każdym momencie jej trwania.
        2.	Karnety ograniczone są 3 miesięcznym terminem ważności, a opłacony karnet nie podlega zwrotowi lub zamianie.        
        
        §4
        ZASADY ANULOWANIA REZERWACJI KORTÓW DO SQUASHA:
        1.	Anulowanie rezerwacji kortu jest możliwa najpóźniej 24 godzin przed zarezerwowanym terminem gry, telefonicznie (nie przez sms), za pośrednictwem aplikacji telefonicznej do rezerwacji kortów lub w recepcji Klubu. W terminie krótszym niż 24h anulowanie odbywa się tylko w trybie warunkowym, w którym recepcja klubu będzie starała się sprzedać anulowany termin.	
        2.	Nieterminowe odwołanie kortu lub nie pojawienie się w zarezerwowanym terminie zobowiązuje Klienta do pokrycia rzeczywistej straty jaką Klub poniósł w związku
         z nieskorzystaniem przez Klienta z usługi.  
        3.	Nie ma możliwości rozliczenia nieobecności na zarezerwowanym terminie lub nieterminowego odwołania rezerwacji kartami partnerów klubu tj: Benefit System (Multisport) czy OK System.
        4.	 W przypadku 2-krotnego nieodwołania stałej rezerwacji (oraz niepojawienia się na korcie w ramach swojej stałej rezerwacji) lub 2-krotnego nieterminowego odwołania rezerwacji kortu przez Klienta (pod rząd) rezerwacja może zostać anulowana, a Klient poinformowany o tym fakcie 	sms-owo.
        5.	W przypadku anulowania rezerwacji przez Klub z podanego w powyższym podpunkcie powodu Klient zobowiązuje się do pokrycia rzeczywistej straty jaką Klub poniósł w związku z nieskorzystaniem przez Klienta z usługi.	
        6.	W przypadku regularnego (co najmniej 3 razy w miesiącu, od co najmniej 2 miesięcy) anulowania (terminowego) przez Klienta rezerwacji kortu do squash, rezerwacja może zostać usunięta, a Klient poinformowany o tym fakcie telefonicznie lub w inny sposób. Odstępstwem od tej zasady jest choroba, kontuzja lub dłuższy wyjazd Klienta, zgłoszone w recepcji Klubu przed dokonaniem anulacji na dłuższy okres czasu.
        
        
        §5
        DANE OSOBOWE
        
        1.	Administratorem danych osobowych Klientów oraz ich przedstawicieli ustawowych jest Jan Rochecki prowadzący działalność gospodarczą pod firmą Jan Rochecki Squash - Training - Events - Tournaments z siedzibą w Rzeszowie ul. Józefa Piłsudskiego 34a, 35-001 Rzeszów , adres e-mail: janekrochecki@o2.pl
        2.	Dane osobowe Klientów będą przetwarzane w celu realizacji umowy, w zakresie wynikającym z zakupionego karnetu lub w razie wejścia jednorazowego w zakresie wynikającym z uiszczonej opłaty oraz w celu podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy.
        3.	W przypadku dokonania rezerwacji kortu za pośrednictwem strony www.squash-akademia.pl. dane osobowe Klientów mogą zostać przekazane Reservise Sp. z o.o.
         z siedzibą w Warszawie, pod adresem: ul. Lindleya 16/301, 02-013 Warszawa w celu koniecznym do realizacji usługi. Polityka przetwarzania danych określa regulamin i polityka prywatności i zapoznanie się z nimi jest niezbędne do realizacji usługi
        4.	Dane osobowe Klientów przetwarzane są tak długo jak to jest niezbędne do wykonania umowy, a po tym czasie przez okres opowiadający okresowi przedawnienia roszczeń.
        5.	Klientowi oraz przedstawicielowi ustawowemu przysługuje prawo do żądania od Administratora  dostępu do swoich  danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania  oraz prawo  do wniesienia sprzeciwu wobec przetwarzania
         i prawo  do przenoszenia danych.
        6.	Klient posiada prawo wniesienia skargi do organu nadzorczego - Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna, iż przetwarzanie danych osobowych narusza przepisy RODO.
        7.	Podanie danych osobowych jest dobrowolne, lecz konieczne do celu do którego zostało zebrane.
        8.	Dane Klienta nie będą podlegały zautomatyzowanemu przetwarzaniu lub profilowaniu.
        "
                multiline
                rows={15}
            />
        </div>
    );
};

export default Regulamin;
